var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preop-data-calc-card py-2",class:{'card-disabled': _vm.disabled}},[_c('b-row',{staticClass:"mb-4"},[_c('b-col',{staticClass:"d-flex justify-content-between"},[_c('h5',{staticClass:"heavy",class:_vm.disabled ? 'card-disabled-label' : 'text-primary'},[_vm._v(" "+_vm._s(_vm.t('preopDataAndCalc_title'))+" ")]),(
                    _vm.checkPermissions(( _obj = {}, _obj[_vm.PERMISSIONS.PATIENT_PREOP_DATA] = _vm.PERMISSIONS_VALUES.READ_WRITE, _obj )) && _vm.patientHasSurgeon
                )?_c('b-button',{attrs:{"variant":"primary","disabled":_vm.addPreOpDataButtonDisabled},on:{"click":function($event){return _vm.goToPreOpData()}}},[_c('b-icon-plus',{staticClass:"mr-1"}),_vm._v(" "+_vm._s(_vm.t('addPreOpData'))+" ")],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{staticClass:"preop-datasets",style:(_vm.scrollYStyle)},_vm._l((_vm.preOpDataSets),function(preOpDataSet){return _c('div',{key:preOpDataSet.preOpDataSetId,staticClass:"preop-dataset",class:{'pb-128px': _vm.get(_vm.preOpDataSets, 'length', 0) == 1}},[_c('div',{staticClass:"collapse-header p-3"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-nowrap text-center mr-2"},[(preOpDataSet.dataSourceId !== _vm.DATA_SOURCES.OCOS)?_c('div',[_c('b-link',{staticClass:"heavy",attrs:{"href":"#"},on:{"click":function($event){return _vm.goToPreOpData(preOpDataSet)}}},[_vm._v(" "+_vm._s(_vm._f("date")(preOpDataSet.updated))+" ")])],1):_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(preOpDataSet.updated))+" ")]),_c('div',{staticClass:"text-gray-dark"},[_vm._v(" "+_vm._s(_vm._f("date")(preOpDataSet.updated,{format: 'HH:mm:ss'}))+" ")])]),_c('div',{staticClass:"text-black ml-2"},[_vm._v(" "+_vm._s(_vm.getUpdatedByInfo(preOpDataSet))+" ")]),_c('div',{staticClass:"ml-auto pl-2 d-flex"},[_c('div',{staticClass:"text-black"},[_vm._v(" "+_vm._s(preOpDataSet.surgeonName)+" ")]),_c('div',{staticClass:"text-small text-gray-dark ml-2"},[_vm._v(" ("+_vm._s(preOpDataSet.surgeonOcosId)+") ")]),_c('b-icon',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                                    'preCalcDataSetCollapse-' + preOpDataSet.preOpDataSetId
                                ),expression:"\n                                    'preCalcDataSetCollapse-' + preOpDataSet.preOpDataSetId\n                                "}],staticClass:"when-open ml-2",attrs:{"icon":"chevron-up","variant":"gray-darker","font-scale":"1"}}),_c('b-icon',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                                    'preCalcDataSetCollapse-' + preOpDataSet.preOpDataSetId
                                ),expression:"\n                                    'preCalcDataSetCollapse-' + preOpDataSet.preOpDataSetId\n                                "}],staticClass:"when-closed ml-2",attrs:{"icon":"chevron-down","variant":"gray-darker","font-scale":"1"}})],1)])]),_c('b-collapse',{attrs:{"visible":"","id":'preCalcDataSetCollapse-' + preOpDataSet.preOpDataSetId}},[_c('div',{staticClass:"ml-4 mr-3 mt-2 preop-dataset-eye-group d-flex"},[_c('div',{staticClass:"mr-2",class:{
                                'mt-4': Object.keys(preOpDataSet.preOpData).length > 1,
                            }},[(
                                    !_vm.addPreOpDataButtonDisabled &&
                                    _vm.currentUser.accessPermissions.PatientPreOpData ==
                                        'ReadWrite' &&
                                    _vm.patientHasSurgeon
                                )?_c('b-icon-clipboard-plus',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 pointer",attrs:{"id":("copy-" + (preOpDataSet.preOpDataSetId)),"font-scale":"1.5","variant":"dark-gray"},on:{"click":function($event){return _vm.copyPreOpData(preOpDataSet)}}}):_vm._e(),_c('b-tooltip',{attrs:{"target":("copy-" + (preOpDataSet.preOpDataSetId))}},[_vm._v(" "+_vm._s(_vm.t('copyPreOpDataNewSet'))+" ")])],1),_c('ul',[_vm._l((['OD', 'OS']),function(opEye){return [(preOpDataSet.preOpData[opEye])?_c('li',{key:opEye,staticClass:"preop-dataset-eye-group-item pb-3"},[_c('div',{staticClass:"preop-dataset-eye-group-item-title",class:_vm.getPreOpDataEyeClass(preOpDataSet.preOpData, opEye)},[_c('b-button',{staticClass:"d-flex p-0",attrs:{"variant":"no-outline","disabled":preOpDataSet.preOpData[opEye].dataSourceId ===
                                                _vm.DATA_SOURCES.OCOS},on:{"click":function($event){return _vm.goToPreOpData(preOpDataSet, opEye)}}},[_c('b-img',{class:_vm.getEyeIconClass(
                                                        preOpDataSet.preOpData[opEye].incomplete
                                                    ),attrs:{"src":_vm.getEyeIcon(opEye)}}),_c('div',{staticClass:"preop-dataset-eye-group-item-title"},[(
                                                        preOpDataSet.preOpData[opEye].incomplete
                                                    )?_c('div',{staticClass:"ml-1 preop-dataset-eye-group-item-title-incomplete"},[_c('em',[_vm._v(" "+_vm._s(opEye)+" "+_vm._s(_vm.t('preopDataAndCalc_Incomplete'))+" ")])]):_c('div',{staticClass:"ml-1",class:_vm.getCheckClass(
                                                            _vm.kPreOpDataStatus.SAVED,
                                                            preOpDataSet.preOpData[opEye].status
                                                        )},[_vm._v(" "+_vm._s(opEye)+" "+_vm._s(_vm.t('preopDataAndCalc_PreOpSaved'))+" ")])])],1)],1),(
                                            _vm.getActiveRefractionInfo(
                                                preOpDataSet.preOpData[opEye]
                                            )
                                        )?_c('div',{staticClass:"d-flex preop-dataset-eye-group-item-detail",class:_vm.getCheckClass(
                                                _vm.kPreOpDataStatus.CALCULATED,
                                                preOpDataSet.preOpData[opEye].status
                                            )},[_c('div',{staticClass:"mr-1 d-flex"},[(
                                                    preOpDataSet.preOpData[opEye].status ===
                                                    _vm.kPreOpDataStatus.SAVED
                                                )?_c('div',[_vm._v(" "+_vm._s(_vm.t('preopDataAndCalc_Refraction'))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.t('preopDataAndCalc_Calculation'))+" ")]),_c('div',[_vm._v("  ("+_vm._s(_vm.getLocalizedRefactionInfo( preOpDataSet.preOpData[opEye] ))+") ")])]),(
                                                preOpDataSet.preOpData[opEye].status ===
                                                    _vm.kPreOpDataStatus.LOCKED ||
                                                preOpDataSet.preOpData[opEye].dataSourceId ===
                                                    _vm.DATA_SOURCES.OCOS
                                            )?_c('div',[_c('b-img',{staticClass:"mr-1 pb-1",attrs:{"src":_vm.padLockIcon,"height":"18","width":"18"}})],1):_vm._e(),(
                                                preOpDataSet.preOpData[opEye].dataSourceId ===
                                                _vm.DATA_SOURCES.OCOS
                                            )?_c('div',{staticClass:"mr-1 text-small text-red"},[_vm._v(" OCOS ")]):_vm._e(),(
                                                preOpDataSet.preOpData[opEye].calculatorVersion
                                            )?_c('div',{staticClass:"mr-1 text-small text-gray-dark"},[_vm._v(" v"+_vm._s(preOpDataSet.preOpData[opEye].calculatorVersion)+" ")]):_vm._e()]):_vm._e(),(
                                            preOpDataSet.preOpData[opEye].targetLensDescription
                                        )?_c('div',{staticClass:"mr-1 preop-dataset-eye-group-item-detail"},[_c('div',{class:_vm.getCheckClass(
                                                    _vm.kPreOpDataStatus.LOCKED,
                                                    preOpDataSet.preOpData[opEye].status
                                                ) ||
                                                _vm.getCheckClass(
                                                    _vm.kPreOpDataStatus.TARGET_LENS_PICKED,
                                                    preOpDataSet.preOpData[opEye].status
                                                )},[_vm._v(" "+_vm._s(_vm.decimalSeparatorFormatter( preOpDataSet.preOpData[opEye] .targetLensDescription, _vm.decimalSeparator ))+" ")])]):_vm._e()]):_vm._e()]})],2)])])],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }